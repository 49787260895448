// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.adduser {
  display: flex;
  width: 100%;
}
.adduser .adduserContainer {
  flex: 6 1;
}
.adduser .formContainer {
  padding: 10px;
  background-color: #d4d0d0;
  border-radius: 8px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  width: 500%;
}
.adduser .formItem {
  margin-bottom: 20px;
}
.adduser .submitButton {
  margin-top: 20px;
}`, "",{"version":3,"sources":["webpack://./src/components/adduser/adduser.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,WAAA;AACJ;AACI;EACI,SAAA;AACR;AACI;EACI,aAAA;EACA,yBAAA;EACA,kBAAA;EACA,2CAAA;EACA,WAAA;AACR;AAEM;EACE,mBAAA;AAAR;AAGM;EACE,gBAAA;AADR","sourcesContent":[".adduser{\n    display: flex;\n    width: 100%;\n\n    .adduserContainer{\n        flex: 6;\n    }\n    .formContainer {\n        padding: 10px; // Add padding\n        background-color: #d4d0d0; // Set background color\n        border-radius: 8px; // Add border radius\n        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);\n        width: 500%; // Add shadow\n      }\n      \n      .formItem {\n        margin-bottom: 20px; // Add margin bottom to form items\n      }\n      \n      .submitButton {\n        margin-top: 20px; // Add margin top to submit button\n      }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
