// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.home {
  display: flex;
}
.home .homeContainer {
  flex: 6 1;
}
.home .homeContainer .widgets, .home .homeContainer .charts {
  display: flex;
  padding: 20px;
  gap: 20px;
}
.home .homeContainer .charts {
  padding: 5px 20px;
}
.home .homeContainer .listContainer {
  box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
  padding: 20px;
  margin: 20px;
}
.home .homeContainer .listContainer .listTitle {
  font-weight: 500;
  color: gray;
  margin-bottom: 15px;
}`, "",{"version":3,"sources":["webpack://./src/pages/home/home.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;AACJ;AACI;EAEI,SAAA;AAAR;AAEQ;EACI,aAAA;EACA,aAAA;EACA,SAAA;AAAZ;AAEQ;EACI,iBAAA;AAAZ;AAGQ;EAEI,sDAAA;EACA,aAAA;EACA,YAAA;AADZ;AAGY;EACI,gBAAA;EACA,WAAA;EACA,mBAAA;AADhB","sourcesContent":[".home{\n    display: flex;\n\n    .homeContainer{\n        \n        flex: 6;\n\n        .widgets, .charts{\n            display: flex;\n            padding: 20px;\n            gap: 20px;\n        }\n        .charts{\n            padding: 5px 20px;\n        }\n\n        .listContainer{\n            -webkit-box-shadow:2px 4px 10px 1px rgba(0,0,0,0.47);\n            box-shadow: 2px 4px 10px 1px rgba(201,201,201,0.47);\n            padding: 20px;\n            margin: 20px;\n\n            .listTitle{\n                font-weight: 500;\n                color: gray;\n                margin-bottom: 15px;\n                \n            }\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
