// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.table .cellWrapper {
  display: flex;
  align-items: center;
}
.table .cellWrapper .image {
  width: 32px;
  height: 322px;
  border-radius: 50%;
  margin-right: 10px;
  object-fit: cover;
}
.table .status {
  padding: 5px;
  border-radius: 5px;
}
.table .status.Approved {
  color: green;
  background-color: rgba(0, 128, 0, 0.151);
}
.table .status.Pending {
  color: goldenrod;
  background-color: rgba(189, 189, 3, 0.103);
}`, "",{"version":3,"sources":["webpack://./src/components/table/table.scss"],"names":[],"mappings":"AACI;EACI,aAAA;EACA,mBAAA;AAAR;AAEQ;EACI,WAAA;EACA,aAAA;EACA,kBAAA;EACA,kBAAA;EACA,iBAAA;AAAZ;AAKI;EACI,YAAA;EACA,kBAAA;AAHR;AAKQ;EACI,YAAA;EACA,wCAAA;AAHZ;AAKQ;EACI,gBAAA;EACA,0CAAA;AAHZ","sourcesContent":[".table{\n    .cellWrapper{\n        display: flex;\n        align-items: center;\n\n        .image{\n            width: 32px;\n            height: 322px;\n            border-radius: 50%;\n            margin-right: 10px;\n            object-fit: cover;\n\n        }\n    }\n\n    .status{\n        padding: 5px;\n        border-radius: 5px;\n\n        &.Approved{\n            color: green;\n            background-color: rgba(0,128,0,0.151);\n        }\n        &.Pending{\n            color: goldenrod;\n            background-color: rgba(189,189,3,0.103);\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
