// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.notification {
  display: flex;
  width: 100%;
}
.notification .notificationContainer {
  flex: 6 1;
}
.notification .notificationContainer a {
  display: inline-block;
  padding: 8px 16px;
  margin-right: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
}
.notification .notificationContainer a.view {
  color: #1890ff;
  border-color: #1890ff;
}
.notification .notificationContainer a.delete {
  color: #f5222d;
  border-color: #f5222d;
}
.notification .notificationContainer a:hover {
  background-color: #f0f0f0;
}
.notification .notificationContainer .ant-table {
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  overflow: hidden;
}`, "",{"version":3,"sources":["webpack://./src/pages/notification/notification.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,WAAA;AACJ;AAAI;EACI,SAAA;AAER;AAAQ;EACI,qBAAA;EACA,iBAAA;EACA,iBAAA;EACA,sBAAA;EACA,kBAAA;AAEZ;AACY;EACE,cAAA;EACA,qBAAA;AACd;AAGY;EACE,cAAA;EACA,qBAAA;AADd;AAIY;EAEE,yBAAA;AAHd;AAMU;EACE,0CAAA;EACA,kBAAA;EACA,gBAAA;AAJZ","sourcesContent":[".notification{\n    display: flex;\n    width: 100%;\n    .notificationContainer{\n        flex: 6;\n\n        a {\n            display: inline-block;\n            padding: 8px 16px; // Adjust padding as needed\n            margin-right: 8px; // Adjust margin as needed\n            border: 1px solid #ccc; // Border style\n            border-radius: 4px; // Optional: Add border radius for rounded corners\n      \n            // Style for the view button\n            &.view {\n              color: #1890ff; // Adjust color as needed\n              border-color: #1890ff; // Adjust border color as needed\n            }\n      \n            // Style for the delete button\n            &.delete {\n              color: #f5222d; // Adjust color as needed\n              border-color: #f5222d; // Adjust border color as needed\n            }\n      \n            &:hover {\n              // Adjust hover styles if needed\n              background-color: #f0f0f0; // Change background color on hover\n            }\n          }\n          .ant-table {\n            box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1); // Add shadow to the table\n            border-radius: 8px; // Add border radius to the table\n            overflow: hidden; // Hide overflow to prevent shadow clipping\n          }\n\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
