// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.chart {
  flex: 4 1;
  box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
  padding: 10px;
  color: gray;
}
.chart .title {
  margin-bottom: 20px;
}
.chart .chartGrid {
  stroke: rgb(228, 225, 225);
}`, "",{"version":3,"sources":["webpack://./src/components/chart/chart.scss"],"names":[],"mappings":"AAAA;EACI,SAAA;EAEA,sDAAA;EACA,aAAA;EACA,WAAA;AACJ;AACI;EACI,mBAAA;AACR;AAEI;EACI,0BAAA;AAAR","sourcesContent":[".chart{\n    flex: 4;\n    -webkit-box-shadow:2px 4px 10px 1px rgba(0,0,0,0.47);\n    box-shadow: 2px 4px 10px 1px rgba(201,201,201,0.47);\n    padding: 10px;\n    color: gray;\n\n    .title{\n        margin-bottom: 20px;\n    }\n\n    .chartGrid{\n        stroke: rgb(228, 225, 225);\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
