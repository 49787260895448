// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.privilage {
  display: flex;
  width: 100%;
}
.privilage .privilageContainer {
  flex: 6 1;
}
.privilage .privilageContainer .chexbox {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}
.privilage .privilageContainer .chexbox .subchexbox {
  display: flex;
  flex-direction: column;
  margin-left: 20px;
}`, "",{"version":3,"sources":["webpack://./src/components/Privilage/privilae.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,WAAA;AACJ;AAAI;EACI,SAAA;AAER;AAAQ;EACI,aAAA;EACA,sBAAA;EACA,mBAAA;AAEZ;AAAY;EACI,aAAA;EACA,sBAAA;EACA,iBAAA;AAEhB","sourcesContent":[".privilage{\n    display: flex;\n    width: 100%;\n    .privilageContainer{\n        flex: 6;\n\n        .chexbox {\n            display: flex;\n            flex-direction: column;\n            margin-bottom: 20px;\n\n            .subchexbox{\n                display: flex;\n                flex-direction: column;\n                margin-left: 20px;\n            }\n          }\n          \n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
