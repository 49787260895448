// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.widget {
  display: flex;
  justify-content: space-between;
  flex: 1 1;
  padding: 10px;
  box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
  border-radius: 10px;
  height: 100px;
}
.widget .left, .widget .right {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.widget .left .title, .widget .right .title {
  font-weight: bold;
  font-size: 14px;
  color: rgb(160, 160, 160);
}
.widget .left .counter, .widget .right .counter {
  font-size: 28px;
  font-weight: 300;
}
.widget .left .link, .widget .right .link {
  width: max-content;
  font-size: 12px;
  border-bottom: 1px solid gray;
}
.widget .left .percentage, .widget .right .percentage {
  display: flex;
  align-items: center;
  font-size: 14px;
}
.widget .left .percentage.positive, .widget .right .percentage.positive {
  color: green;
}
.widget .left .percentage.negative, .widget .right .percentage.negative {
  color: red;
}
.widget .left .icon, .widget .right .icon {
  font-size: 18px;
  padding: 5px;
  border-radius: 5px;
  align-self: flex-end;
}`, "",{"version":3,"sources":["webpack://./src/components/widget/widget.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,8BAAA;EACA,SAAA;EACA,aAAA;EAEA,sDAAA;EACA,mBAAA;EACA,aAAA;AACJ;AACI;EACI,aAAA;EACA,sBAAA;EACA,8BAAA;AACR;AAEQ;EACI,iBAAA;EACA,eAAA;EACA,yBAAA;AAAZ;AAGQ;EACI,eAAA;EACA,gBAAA;AADZ;AAGQ;EACI,kBAAA;EACA,eAAA;EACA,6BAAA;AADZ;AAGQ;EACI,aAAA;EACA,mBAAA;EACA,eAAA;AADZ;AAGY;EACI,YAAA;AADhB;AAGY;EACI,UAAA;AADhB;AAIQ;EACI,eAAA;EACA,YAAA;EACA,kBAAA;EACA,oBAAA;AAFZ","sourcesContent":[".widget{\n    display: flex;\n    justify-content: space-between;\n    flex: 1;\n    padding: 10px;\n    -webkit-box-shadow: 2px 4px  10px 1px rgba(0,0,0,0.47);\n    box-shadow: 2px 4px 10px 1px rgba(201,201,201,0.47);\n    border-radius: 10px;\n    height: 100px;\n\n    .left, .right{\n        display: flex;\n        flex-direction: column;\n        justify-content: space-between;\n\n\n        .title{\n            font-weight: bold;\n            font-size: 14px;\n            color: rgb(160, 160, 160);\n        }\n\n        .counter{\n            font-size: 28px;\n            font-weight: 300;\n        }\n        .link{\n            width: max-content;\n            font-size: 12px;\n            border-bottom: 1px solid gray;\n        }\n        .percentage{\n            display: flex;\n            align-items: center;\n            font-size: 14px;\n\n            &.positive{\n                color: green;\n            }\n            &.negative{\n                color: red;\n            }\n        }\n        .icon{\n            font-size: 18px;\n            padding: 5px;\n            border-radius: 5px;\n            align-self: flex-end;\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
